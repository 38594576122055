import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

import { SharedModule } from '@shared';
import { MaterialModule } from '../material.module';
import { LandingPageComponent } from './landing-page.component';
import { LandingPageRoutingModule } from './landing-page-routing.module';
import { AnimatedDigitModule } from '@app/animated/animated-digit/animated-digit.module';
import { DynamicImageModule } from '@app/dynamic-image/dynamic-image.module';
import { RegisterFormModule } from '@app/register-form/register-form.module';
import { WebsiteFragmentsModule } from '@app/website_fragments/website-fragments.module';
import { LcaComponent } from '@app/website_fragments/products/lca/lca.component';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule,
    MaterialModule,
    LandingPageRoutingModule,
    AnimatedDigitModule,
    DynamicImageModule,
    RegisterFormModule,
    WebsiteFragmentsModule,
  ],
  declarations: [LandingPageComponent],
  exports: [LandingPageComponent],
})
export class LandingPageModule {}
