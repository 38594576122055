import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LanguageRoutingResolver } from '@app/i18n/language-routing/language-routing.resolver';

const routes: Routes = [
  {
    path: 'fr',
    //resolver currently only supports French & German.
    resolve: { resolveData: LanguageRoutingResolver },
    component: LandingPageComponent,
    runGuardsAndResolvers: 'paramsChange',
  },

  {
    path: 'lcc',
    component: LandingPageComponent,
    data: { title: 'global.title' },
  },

  {
    path: '',
    component: LandingPageComponent,
    data: { title: 'global.title' },
  },
  // Fallback when no prior route is matched
];

@NgModule({
  imports: [RouterModule.forChild(routes), TranslocoModule],
  exports: [RouterModule],
  providers: [],
})
export class LandingPageRoutingModule {}
